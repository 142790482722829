// import styled from 'styled-components';
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import { myContext } from 'src/themeProvider'

import { HireMe } from 'components/Button'
import ContentWrapper from 'components/Content'
import Layout from 'components/Layout'

import * as t from 'utils/Typography'
import { AboveFold, Background } from 'utils/styles'

function RenderPage(props) {
  const { theme, changeTheme, data } = props

  return (
    <Background theme={theme}>
      <Layout theme={theme} onChangeTheme={changeTheme}>
        <AboveFold theme={theme}>
          <ContentWrapper theme={theme} style={{ justifyContent: 'center', alignItems: 'center' }}>
            <GatsbyImage
              style={{ maxWidth: '750px', margin: '0 auto' }}
              image={getImage(data.header)}
              alt="Bikini Doodle"
            />
            <t.H1 primary align="left">
              Netflax Calendar
            </t.H1>
            <t.H3 secondary align="left">
              A bot that automatically adds the release dates of your favorite Netflix shows to your Google Calendar.
            </t.H3>
            <t.P align="left">Developed using Python with Dropbox, Twitter and GoogleCalendar API’s and Celery.</t.P>
            <t.P align="left">
              The aim of NetflaxCalendar is to add an event to a calendar in Google Calendar with the publication date
              of your favorite Netflix series and films. To achieve that, it checks the feed of different Twitter bots,
              such as NetflixBot or ElGallodeNetflix. Every time a new tweet is published, it adds an event with the
              publication date to your calendar.
            </t.P>
            <HireMe
              large
              onClick={() => window.open('https://github.com/manuelrdsg/NetflaxCalendar', '_blank')}
              book
              theme={theme}
              style={{ marginTop: '30px' }}>
              Check it out!&nbsp;
              <span role="img" aria-label="zap">
                ⚡️
              </span>
            </HireMe>
          </ContentWrapper>
        </AboveFold>
      </Layout>
    </Background>
  )
}

function NetflaxPage(props) {
  const { data } = props
  return (
    <myContext.Consumer>
      {(context) => <RenderPage theme={context.theme} changeTheme={context.changeTheme} data={data} />}
    </myContext.Consumer>
  )
}

export default NetflaxPage

export const pageQuery = graphql`
  query {
    header: file(relativePath: { eq: "netflax_header.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: BLURRED)
      }
    }
  }
`
